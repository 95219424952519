import { APP_DEF_ID, DASHBOARD_PAGES_HELP_ID } from '../constants/helpIds';
import { handleSetAsDynamicPage } from '../pages-utils/dynamic';
import { panelDefinitions } from '../panel-definitions';
import { createPage } from '../pages-utils/editor';
import { reportError } from '../pages-utils/errors';
import {
  ActionType,
  ControllerType,
  EditorAppContext,
  EditorPlatformApp,
} from './types';
import { eventNames } from '../constants/eventNames';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export function createEditorPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;
  let flowAPI: EditorScriptFlowAPI;

  return {
    async editorReady(editorSDK, appDefinitionId, options, _flowAPI) {
      const { initialAppData, firstInstall } = options;

      flowAPI = _flowAPI;

      const locale = await editorSDK.environment.getLocale();
      const adminPagesScriptUrl = initialAppData.editorScriptUrl;

      const {
        experiments,
        fedops,
        errorMonitor,
        environment,
        translations,
      } = flowAPI;
      const { isEditorX } = environment;

      appContext = {
        flowAPI,
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        isEditorX: environment.isEditorX,
        sdkScriptSrc: editorSDK.info.getSdkVersion(appDefinitionId).scriptSrc,
        reportError(error) {
          reportError(errorMonitor, error, {
            firstInstall,
            isEditorX,
          });
        },
        instance: await editorSDK.info.getAppInstance(appDefinitionId),
      };

      const adminPages = await editorSDK.pages.getApplicationPages(
        appDefinitionId,
      );

      if (!firstInstall) {
        return;
      }

      if (adminPages && adminPages.length === 0) {
        return editorSDK.editor.openModalPanel(
          appDefinitionId,
          panelDefinitions.addPagePanel(appContext.sdkScriptSrc),
        );
      }

      return createPage(editorSDK, flowAPI.httpClient, appDefinitionId);
    },

    async getAppManifest({ appManifestBuilder }) {
      return appManifestBuilder
        .configureController(
          ControllerType.SingleAdminPage,
          (controllerBuilder) => {
            controllerBuilder.set({
              visibility: 'NEVER',
            });
          },
        )
        .configurePages((pagesBuilder) => {
          pagesBuilder // page builder is not fot a specific widget
            .configureState('custom-page-state', (pagesStateBuilder) => {
              pagesStateBuilder.addAction({
                advanced: false,
              });
            })
            .addAction(
              {
                type: 'page_delete',
              },
              {
                type: 'page_rename',
              },
              {
                title: appContext.translate('page_actions.set_as_dynamic_page'),
                icon: 'page_dynamic_icon',
                onClick: ({ detail: { pageRef } }) => {
                  const {
                    editorSDK,
                    appDefinitionId,
                    flowAPI: { httpClient },
                  } = appContext;
                  handleSetAsDynamicPage(
                    pageRef,
                    editorSDK,
                    appDefinitionId,
                    httpClient,
                  );
                },
              },
            )
            .addSettingsTab(
              {
                title: appContext.translate('page_settings.page_info'),
                type: 'page_info',
                url: panelDefinitions.pageInfoPanel(appContext.sdkScriptSrc)
                  .url,
              },
              {
                title: appContext.translate('page_settings.page_permissions'),
                type: 'permissions',
                url: panelDefinitions.permissionsPanel(appContext.sdkScriptSrc)
                  .url,
              },
            );
        })
        .configurePagesTab((pagesTabBuilder) => {
          pagesTabBuilder
            .set({
              displayName: appContext.translate('dashboard-pages'),
              helpId: DASHBOARD_PAGES_HELP_ID,
            })
            .addAction({
              title: appContext.translate('dashboard-pages'),
              icon: 'addPagePagesPanel',
              type: 'add_page',
              onClick: () => {
                createPage(
                  appContext.editorSDK,
                  appContext.flowAPI.httpClient,
                  appContext.appDefinitionId,
                );
              },
            })
            .setGrouping('routers');
        })
        .build();
    },
    async handleAction({ type }) {
      console.log('TYPE>>>>>>>>>>>>', type);
      switch (type) {
        case ActionType.RemoveApp: {
          alert('Hi ting 3!');
          break;
        }
      }
    },

    async onEvent(event, editorSDK) {
      switch (event.eventType) {
        case eventNames.dataSectionAppClicked:
          return createPage(editorSDK, flowAPI.httpClient, APP_DEF_ID);
      }
    },
  };
}
