import { HandleActionFn } from '@wix/platform-editor-sdk';
import {
  EditorReadyFn,
  Experiments,
  FlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
  OnEventFn,
} from '@wix/yoshi-flow-editor';

export enum ActionType {
  RemoveApp = 'removeApp',
}

export interface EditorPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  handleAction: HandleActionFn;
  onEvent: OnEventFn;
}

export type EditorAppContext = {
  flowAPI: FlowAPI;
  appDefinitionId: string;
  editorSDK: FlowEditorSDK;
  experiments: Experiments;
  fedops: FlowAPI['fedops'];
  reportError(error: unknown): void;
  isEditorX: boolean;
  translate: FlowAPI['translations']['t'];
  sdkScriptSrc: string;
  instance: string;
};

export enum ControllerType {
  SingleAdminPage = 'singleAdminPageController',
}
