import { PageRef } from '@wix/platform-editor-sdk';
import {
  FlowEditorSDK,
  ComponentRef,
  IHttpClient,
} from '@wix/yoshi-flow-editor';

const TOKEN = 'token';
const PERMISSIONS_PARAMS = {
  group_id: '00000000-0000-0000-0000-000000000001',
  permissions_id: 'cce6b124-b14c-4383-aee9-5e0814811ae2',
  context: 'Pages',
};

export const panelUrlBuilder = (
  componentRef: ComponentRef,
  panelName: string,
) => {
  const inWatchMode = process.env.NODE_ENV !== 'production';
  // During yoshi-flow-editor start we want have local rendered settings panel. For prod - we are using static html file.
  const baseUrl = inWatchMode
    ? `https://localhost:3200/settings/${panelName}.html`
    : `./settings/${panelName}.html`;

  return `${baseUrl}?componentId=${componentRef.id}`;
};

export function openSettingsPanel(
  editorSDK: FlowEditorSDK,
  panelName: string,
  { componentRef }: { componentRef: ComponentRef },
) {
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: 'Widget Settings',
    url: panelUrlBuilder(componentRef, panelName),
    height: 240,
    componentRef,
  });
}

export async function addComponent(
  editorSDK: FlowEditorSDK,
  componentDefinition: any,
) {
  const pageRef = await editorSDK.pages.getCurrent(TOKEN);

  return editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
}

const getPageStructure = (token: string) => ({
  data: {
    hideTitle: false,
    isLandingPage: true,
    indexable: false,
    hidePage: true,
    managingAppDefId: token, // this will make it to be under dashboard pages in the pages list
    pageSecurity: {
      requireLogin: true,
    },
  },
  components: [],
});

export const setPagePermissions = async (
  pageRef: PageRef,
  httpClient: IHttpClient,
) => {
  const data = {
    permissions: [
      {
        id: PERMISSIONS_PARAMS.permissions_id,
        context: PERMISSIONS_PARAMS.context,
        resourceIds: [pageRef.id],
      },
    ],
  };
  const url = `https://editor.wix.com/_api/members-groups-web/v1/groups/${PERMISSIONS_PARAMS.group_id}/permissions`;
  httpClient.post(url, data);
};

export async function addPageToComponent(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  pageRef: PageRef,
) {
  await editorSDK.components.add('token', {
    // this connects the page to my viewer script
    componentDefinition: {
      componentType: 'platform.components.AppController',
      data: {
        controllerType: 'singleAdminPageController',
        name: 'admin-pages-controller',
        applicationId: appDefinitionId,
        settings: JSON.stringify({
          pageId: pageRef.id,
        }),
      },
    },
    pageRef,
  });
}

export function openPagesPanel(editorSDK: FlowEditorSDK, pageRef: PageRef) {
  return editorSDK.editor.openPagesPanel('token', {
    renameEnabled: true,
    pageRef,
    initialSettingsTabType: 'PAGE_INFO',
  });
}

export async function createPage(
  editorSDK: FlowEditorSDK,
  httpClient: IHttpClient,
  token: string,
) {
  await editorSDK.history.add(token, { label: 'History' });

  const pageRef = await editorSDK.pages.add(token, {
    title: 'New Page',
    definition: getPageStructure(token),
    shouldAddMenuItem: false,
  });

  await setPagePermissions(pageRef, httpClient);

  await addPageToComponent(editorSDK, token, pageRef);

  return openPagesPanel(editorSDK, pageRef);
}
