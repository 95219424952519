import { PageRef } from '@wix/platform-editor-sdk';
import { FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import {
  addPageToComponent,
  openPagesPanel,
  setPagePermissions,
} from './editor';

async function updatePageDefinitions(pageRef, appDefinitionId, editorSDK) {
  editorSDK.pages.data.update('token', {
    pageRef,
    data: { managingAppDefId: appDefinitionId, isLandingPage: true },
  });
}

async function markNewDynamicPageAsAdminPage(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  pageRef: PageRef,
  httpClient: IHttpClient,
) {
  await updatePageDefinitions(pageRef, appDefinitionId, editorSDK);
  await setPagePermissions(pageRef, httpClient);
  await addPageToComponent(editorSDK, appDefinitionId, pageRef);
  return openPagesPanel(editorSDK, pageRef);
}

export async function handleSetAsDynamicPage(
  pageRef: PageRef,
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  httpClient: IHttpClient,
) {
  const databindingApi: any = await editorSDK.document.application.getPublicAPI(
    'token',
    { appDefinitionId: 'dataBinding' },
  );
  const pageRefs = await databindingApi.convertStaticPageToDynamic({
    pageRef,
  });
  const newPage = pageRefs.filter((page) => page.id !== pageRef.id)[0];
  return markNewDynamicPageAsAdminPage(
    editorSDK,
    appDefinitionId,
    newPage,
    httpClient,
  );
}
