import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const panelDefinitions = {
  addPagePanel: (sdkScriptSrc) => ({
    width: 744,
    height: 533,
    shouldHideHeader: true,
    url: getPanelUrl('panelsDummyWidget', 'AddPage'),
  }),
  pageInfoPanel: (sdkScriptSrc) => ({
    title: 'Page Settings',
    url: getPanelUrl('panelsDummyWidget', 'PageInfo'),
  }),
  permissionsPanel: (sdkScriptSrc) => ({
    title: 'Page Permissions',
    url: getPanelUrl('panelsDummyWidget', 'Permissions'),
  }),
};
